import React from 'react';
import { NavigationTopBarApp } from '@material-bank/navigation-top-bar-application';
import { createRoot } from 'react-dom/client';

const root = document.getElementById('navigation-top-bar-root');

if (root) {
  createRoot(root).render(<NavigationTopBarApp />);
} else {
  console.error('Cannot find the root element');
}
